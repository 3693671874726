<template>
    <div class="bottom">
        <!-- <div class="bottom-top-line"></div> -->
        <div class="bottom-logo" @click="gotoSubpage()">
            <img :src="cn_enobj.site.index.bottom_logo" alt="" loading="lazy"/>
        </div>
        <div class="bottom-box3"> </div>
        <div class="bottom-box4">{{cn_enobj.site.index.footer_text}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cn_enobj:{}
        }
    },
    created(){
        this.changeL(this.$i18n.locale)
    },
    methods:{
        changeL(str){
            this.locale = str
            this.cn_enobj = str == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
        },
         /**
         * 前往其他菜单页面函数
         * @param  type  参数
         * @returns 返回值
         */
        gotoSubpage(){
            this.$router.push('/')
        }
    }
}
</script>

<style lang="less" scoped>
.bottom{
    background: #FFFFFF; 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 9vw;
    position: relative;
}

.bottom-logo{
    width: 100%;
    text-align: center;
    img{
        width: 10vw; 
        margin-top: 5vh;
        margin-bottom: 6vh;
    }
}

.bottom-box3{
    width: 100%;
    min-height: 1px;
    background: rgba(181, 222, 183, 1)
}
.bottom-box4{
    width: 100%;
    font-size: 1.6vmin;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    margin: 2vh 0px;
    text-align: center;
}

/* bottom区域样式--end */
@media screen and (max-width: 600px) {
    .bottom-logo{
        width: 100%;
        text-align: center;
        img{
            width: 35vw; 
            margin-top: 5vh;
            margin-bottom: 6vh;
        }
    }
    .bottom-box4{
        font-size: 2.4vmin;
    }
}
</style>