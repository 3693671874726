<template>
  <div class="pg" id="box">
    <Top :topIndex="1" @changeL="changeL"></Top>
    <div style="min-height:10vh">
        <img :src="cn_enobj.site.index.home_logo" class="zhanweiImg" alt="">
    </div>
    <div class="showbox" v-show="!showItem8">
        <!-- 昊辰介绍 -->
        <div class="aboutUs-item2" v-show="cn_enobj.site.aboutUs.introduction.is_show">
            <div class="labelsTop">
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.introduction.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.introduction.subtitle}}</div>
                    </div>
                </div>
            </div>
            <div class="aboutUs-item2-box reveal-bottom">
                <div v-html="cn_enobj.site.aboutUs.introduction.content"></div>
            </div>
        </div>
        <!-- 团队成员 -->
        <div class="aboutUs-item7" v-show="cn_enobj.site.aboutUs.teams.is_show">
            <div class="labelsTop">
                 <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.teams.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.teams.subtitle}}</div>
                    </div>
                </div>
            </div>
            <div class="aboutUs-item7-box">
                <div class="aboutUs-item7-box-card reveal-r" v-for="(i,index) in teamList" :key="index">
                    <el-image :src="i.pic"  style="width: 100%;height: 100%;display: block;" @click="showgbmengban=true"  lazy></el-image>
                    <div class="aboutUs-item7-box-card-transform" :style="{'visibility':showgbmengban ?'':'hidden'}"
                       @click="gotoDetaile(index,i)">
                        <span class="aboutUs-item7-box-card-transform-title">{{locale ==='zh'?i.name:i.en_name}}</span>
                        <span class="aboutUs-item7-box-card-transform-post">{{locale ==='zh'?i.post:i.en_post}}</span>
                        <img src="@/assets/img/gbmengban.png" class="gbmengbna" @click.stop="gbmengban()" alt="">
                    </div>
                    <div class="aboutUs-item7-box-card-text">
                        <p style="font-size:4.6vmin;font-weight:500;text-align: center;">{{locale ==='zh'?i.name:i.en_name}}</p>
                        <div style="font-size:3.8vmin;font-weight:400;text-align: center;margin-bottom:1vh">{{locale ==='zh'?i.post:i.en_post}}</div>
                        <div class="a-item7-body" v-html="locale ==='zh'?i.introduce:i.en_introduce"></div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- 投资理念 -->
        <div class="aboutUs-item4" v-show="cn_enobj.site.aboutUs.theory.is_show">
            <div class="labelsTop">
                 <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.theory.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.theory.subtitle}}</div>
                    </div>
                </div>
            </div>

             <el-carousel indicator-position="none" arrow="never"   ref="cardShow" :autoplay="false" >
                <el-carousel-item v-for="(item,index) in investmentPhilosophyArr" :key="index">
                    <div class="aboutUs-item4-box" >
                        <div class="aboutUs-item4-box-right reveal-bottom show600 ">
                            <img :src="item.pic_big"  style="width: 100%;height: 100%;"/>
                        </div>
                        <div class="aboutUs-item4-box-left reveal-bottom" >
                            <div class="aboutUs-item4-box-left-top">
                                <img src="@/assets/img/xinwen_l.png" class="swiper-button-prev aboutUs-item4topbtn" @click="switchData('left')" alt="">
                                <div style="text-align: center;">{{locale ==='zh'?item.title:item.en_title}}</div>
                                <img src="@/assets/img/xinwen_r.png" class="swiper-button-next aboutUs-item4topbtn" @click="switchData('right')"  alt="">
                            </div>
                            <div class="aboutUs-item4-box-right-bottom">
                                {{locale ==='zh'?item.introduce:item.en_introduce||''}}
                            </div>
                        </div>
                        <div class="aboutUs-item4-box-right reveal-bottom show-600 ">
                            <img :src="item.pic_big"  style="width: 100%;height: 100%;"/>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <!-- <div class="swiper-container">
                <div class="swiper-wrapper">
                     <div class="aboutUs-item4-box swiper-slide" 
                      v-for="(item,index) in investmentPhilosophyArr" :key="index">
                        <div class="aboutUs-item4-box-right reveal-bottom show600 ">
                            <img :src="item.pic_big"  style="width: 100%;height: 100%;"/>
                        </div>
                        <div class="aboutUs-item4-box-left reveal-bottom" >
                            <div class="aboutUs-item4-box-left-top">
                               
                                <img src="@/assets/img/xinwen_l.png" @click="switchData('left')" alt=""
                                class="swiper-button-prev aboutUs-item4topbtn"  >
                                <div class="aboutUs-item4topbtn"></div>
                                <div style="text-align: center;">{{locale ==='zh'?item.title:item.en_title}}</div>
                                 <div class="aboutUs-item4topbtn"></div>
                                <img src="@/assets/img/xinwen_r.png"  @click="switchData('right')"  alt=""
                                class="swiper-button-next aboutUs-item4topbtn">
                            </div>
                            <div class="aboutUs-item4-box-right-bottom">
                                {{locale ==='zh'?item.introduce:item.en_introduce||''}}
                            </div>
                        </div>
                        <div class="aboutUs-item4-box-right reveal-bottom show-600 ">
                            <img :src="item.pic_big" ref="img_ref"  style="width: 100%;height: 100%;"/>
                        </div>
                    </div>
                    
                </div>
            </div> -->
            <!-- <swiper :options="swiperOption" ref="mySwiper"  @click-slide="handleClickSlide" style="position: relative;">
                <swiper-slide  v-for="(item,index) in investmentPhilosophyArr" :key="index">
                    <div class="aboutUs-item4-box">
                        <div class="aboutUs-item4-box-right reveal-bottom show600 ">
                            <img :src="item.pic_big"  style="width: 100%;height: 100%;"/>
                        </div>
                        <div class="aboutUs-item4-box-left reveal-bottom" >
                            <div class="aboutUs-item4-box-left-top">
                              
                                <div class="aboutUs-item4topbtn"></div>
                                <div style="text-align: center;">{{locale ==='zh'?item.title:item.en_title}}</div>
                                <div class="aboutUs-item4topbtn"></div>
                                
                            </div>
                            <div class="aboutUs-item4-box-right-bottom">
                                {{locale ==='zh'?item.introduce:item.en_introduce||''}}
                            </div>
                        </div>
                        <div class="aboutUs-item4-box-right reveal-bottom show-600 ">
                            <img :src="item.pic_big" ref="img_ref"  style="width: 100%;height: 100%;"/>
                        </div>
                    </div>
                </swiper-slide>
                <img src="@/assets/img/xinwen_l.png" slot="button-prev" alt="" 
                class="swiper-button-prev aboutUs-item4topbtn"/>
                <img src="@/assets/img/xinwen_r.png" slot="button-next" alt=""
                class="swiper-button-next aboutUs-item4topbtn"/>
            </swiper> -->
        </div>
        <!-- 公司荣誉 -->
        <div class="aboutUs-item5"  v-show="cn_enobj.site.aboutUs.honors.is_show">
            <div class="labelsTop" style="width:100%; padding-top:0">
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.honors.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.honors.subtitle}}</div>
                    </div>
                </div>
            </div>
            <div class="aboutUs-item5-box">
                <div class="aboutUs-item5-box-left reveal-bottom">
                    <img :src="gloryListObjimg" style="width: 100%;height: 100%;" alt="">
                </div>
                <div class="aboutUs-item5-box-right reveal-bottom">
                    <div class="aboutUs-item5-box-right-block" v-for="(i,index) in gloryList" :key="index">
                        <div class="aboutUs-item5-right-li">
                            <div>{{new Date(i.publish_time).getDate()}}</div>
                            <div class="aboutUs-item5-right-li-text">{{new Date(i.publish_time).getFullYear()}}.{{new Date(i.publish_time).getMonth()+1}}</div>
                        </div>
                        <div class="aboutUs-item5-box-right-block-wenan">
                            <div class="wenantitle" @click="goDetaile(i)" @mousemove="changeImg(i)">{{locale === 'zh'?i.title:i.en_title}}</div>
                            <div class="wenanbody">{{locale === 'zh'?i.introduce:i.en_introduce||''}}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="aboutUs-item5-img">
                <div class="aboutUs-item5-imgbtn" @click="goHonor()">VIEW MORE</div>
            </div>
        </div>
        <!-- 昊辰文化 -->
        <div class="aboutUs-item6" v-show="cn_enobj.site.aboutUs.culture.is_show">
            <div class="labelsTop">
                <div style="display: flex;"   class="reveal-r" >
                    <div style="text-align: center;">
                        <div class="labels">{{cn_enobj.site.aboutUs.culture.title}}</div>
                        <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.aboutUs.culture.subtitle}}</div>
                    </div>
                </div>
            </div>
            
            <div class="aboutUs-item6-box">
                <div class="aboutUs-item6-box-card reveal-bottom"  v-for="(i,index) in aboutUs6List" :key="index">    
                    <!--  :style="{width:locale === 'en'?'auto':'','border-radius':locale === 'en'?'4vmin':'', height:locale === 'en'?'auto':'',
                    padding:locale === 'en'? '0 1vw ':'1vw'}" -->
                    <div class="card-2"  >{{locale == 'zh'?i.title:i.en_title}}</div>
                    <div class="card-3">{{locale == 'zh'?i.introduce:i.en_introduce||''}}</div>
                </div>
            </div>
            
        </div>
    
    </div>

    <!-- 个人信息 -->
    <div class="aboutUs-item8" v-if="showItem8">
        <div class="aboutUs-item8-box2">
            <div  class="aboutUs-item8-box2-btn" @click="comeback()">
                <img src="@/assets/img/返回.png"  style="width: 30px;height:30px;margin: 0 10px 0 0;" alt="">
                <span style="color: #01AE0D;font-size: 2vmin;">{{cn_enobj.site.components.bottom.back_bottom}}</span>
            </div>
        </div>
        <div class="aboutUs-item8-box">
            <div class="aboutUs-item8-left">
                <el-image :src="teamObj.pic_big"  style="width: 100%;height: 100%;display: block;"  lazy></el-image>
            </div>
            <div class="aboutUs-item8-right">
                <div class="aboutUs-item8-right-aboutUs-item1">
                    <div style="font-size:2.8vmin;margin-bottom:10px">{{locale ==='zh'?teamObj.name:teamObj.en_name}}</div>
                    <div style="font-size:2vmin;margin-bottom:10px">{{locale ==='zh'?teamObj.post:teamObj.en_post}}</div>
                </div>
                <div class="aboutUs-item8-right-aboutUs-item2"></div>
                <div class="aboutUs-item8-right-aboutUs-item3" v-html="locale === 'zh'?teamObj.introduce:teamObj.en_introduce||''"></div>
            </div>
        </div>
        
    </div>
    <Bottom></Bottom>
    <ReturnTop></ReturnTop>
  </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'


let vm = null;
export default {  
    components:{Top,Bottom,ReturnTop},
    data () {
        return {
            scrollReveal: scrollReveal(),
            type:1, // 1:初始状态 |2:非初始状态
            showMenu:false,// 是否显示蒙版
            investmentPhilosophyArr : [] ,// 投资理念
            showItem8:false,// 是否显示 个人详细信息
            teamList:[ ], // 团队数组
            locale:'',  // 中文状态 标题下方显示英文
            teamObj:{} , // 选中的团队成员
            aboutUs6List:[], // 昊辰文化 | 公司文化
            gloryList:[], // 公司荣誉数据
            gloryListObjimg:'', // 公司荣誉图片
            cn_enobj:{},
            showgbmengban:false,//
            imgH:400,
          
          
        }
    },
   
    //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9,});
        this.scrollReveal.reveal('.reveal-b2', {origin: 'bottom',...this.global,distance: '20px'});
     
    },
    created(){
        this.getTeamData()   // 获取团队成员
        this.getTheoryData() // 获取投资理念
        this.getGloryData() // 获取公司荣誉
        this.getCultureData() //获取公司文化      
        this.changeL(this.$i18n.locale)
    
    },
    methods: {
        // 获取团队成员
        getTeamData(){
            this.$axios({
                url: '/api/content/list/team?all=true',
                method: 'GET'
            }).then(res=>{
                this.teamList =res.data.data
            })
           
        }, 

         // 获取投资理念
        getTheoryData(){
            this.$axios({
                url: '/api/content/list/theory?all=true',
                method: 'GET'
            }).then(res=>{
                this.investmentPhilosophyArr = res.data.data
            })
        }, 
        // 获取公司荣誉
        getGloryData(){
            this.$axios({
                url: '/api/content/list/glory',
                method: 'GET'
            }).then(res=>{
                this.gloryList = res.data.data.data.slice(0, 3) 
                this.gloryListObjimg = res.data.data.data[0].pic_big
            })
        }, 
        // 获取公司文化
        getCultureData(){
            this.$axios({
                url: '/api/content/list/culture?all=true',
                method: 'GET'
            }).then(res=>{
                this.aboutUs6List = res.data.data
            })
        }, 
        // 切换图片
        changeImg(i){
            this.gloryListObjimg= i.pic_big
        },
        // 切换中英文
        changeL(str){
            this.locale = str
            this.cn_enobj = this.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
       },
        /**
         * 打开菜单函数
         * @param  参数
         * @returns 返回值
         */
        openMenu() {
            this.showMenu = !this.showMenu
        },
        
        
        // 前往详细信息
        gotoDetaile(index,val){
            console.log('这蒙版',this.showgbmengban)
            if(!this.showgbmengban)return
            if(!this.cn_enobj.site.aboutUs.teams.is_show)return
            this.showItem8 = true
            this.$scrollFun()
            this.$axios({
                url:'/api/content/detail/team?id='+val.id,
                method:'get'
            }).then(res=>{
                this.teamObj=res.data.data
            })
            
        },
       
        // 返回
        comeback(){
            this.showItem8 = false
            this.$scrollFun()
        },

        // 切换数据 type=>2左|1右
        switchData(type){
            if(type === 'right'){
                this.$refs.cardShow.next()
            }else{
                this.$refs.cardShow.prev()
            }
        },
        // 公司荣誉
        goHonor(){
            this.$router.push('/honor')
        },
        // 前往公司荣誉详情
        goDetaile(i){
            this.$router.push({path:'/inCaseDetaile', query:{type:'glory',id: i.id}})
        },
        gbmengban(){
            this.showgbmengban = false
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../src/assets/css/pc/aboutUs_pc.less"; 
</style>